module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


    var companyLogoInOtherTariffs = flight.carrier && flight.carrier.codeSystem === 'FLY_ARYSTAN' ? flight.carrier.airlineCode + '-' + flight.carrier.codeSystem : flight.carrier && flight.carrier.uid || 'noCarrierField';

    var objFields = ['businessLoungeAccess', 'seatChoice', 'priorityBoarding', 'extraMileageBonus', 'classUpgradeAvailable', 'transferIncluded', 'mealService']
    var additionalDetails = additionalDetails || {};
    _.each(objFields, function (key) {
        additionalDetails[key] = additionalDetails[key] != null ? additionalDetails[key] : null;
	});
;
__p += '\n<div class="b-ticket-additional-details b-additional-details">\n    <div class="b-additional-details__container">\n    <div class="b-additional-details__column b-additional-details__column--type-caption">\n        <div class="b-additional-details__row">\n            <div class="b-additional-details__company-logo g-icon" style="background-image: url(\'/assets/img/logos/' +
((__t = ( companyLogoInOtherTariffs )) == null ? '' : __t) +
'-black.svg\')" data-toggle="tooltip" data-placement="bottom"></div>\n        </div>\n        ';
 _.each(objFields, function (key) {
			if (additionalDetails[key] != null) { ;
__p += '\n            <div class="b-additional-details__row">' +
((__t = ( L10N.get('ticket.additionalDetails.' + key) )) == null ? '' : __t) +
'</div>\n        ';
  }
        }) ;
__p += '\n    </div>\n    <div class="action-columns">\n        <div class="b-additional-details__column">\n            <div class="b-additional-details__row">\n                <div class="b-additional-details__name">' +
((__t = ( flightFare.tariffs.length !== 0 ? flightFare.tariffs.join(', ') : '&nbsp;')) == null ? '' : __t) +
'</div>\n                ';
 if (!_.isEmpty(flightFare.servicesDetails.fareBasis) && !_.isEmpty(flightFare.fareBasis)) { ;
__p += '\n                    ';
 flightFare.fareBasis.forEach(function (fareBasis, fareBasisIndex) {
                      var fareRule = flightFare.fareRules && flightFare.fareRules[fareBasisIndex] ? Formatter.btoa(flightFare.fareRules[fareBasisIndex]) : "";
                    ;
__p += '\n                    <div class="b-additional-details__basis b-fare-rules__rule-carrier-open" data-token="' +
((__t = ( flightFare.flightToken )) == null ? '' : __t) +
'" data-fare-rules="' +
((__t = ( fareRule )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( fareBasis )) == null ? '' : __t) +
'\n                    </div>\n                    ';
 }) ;
__p += '\n                ';
 } ;
__p += '\n            </div>\n            ';
 _.each(objFields, function (key) {
                if (additionalDetails[key] != null) { ;
__p += '\n                <div class="b-additional-details__row">' +
((__t = ( L10N.get('Common.' + (additionalDetails[key] ? 'yes' : 'no')))) == null ? '' : __t) +
'</div>\n            ';
 }
			}) ;
__p += '\n        </div>\n    </div>\n</div>\n</div>\n';

}
return __p
}