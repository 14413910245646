module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


var airlines = [];


_.each(flightFares, function (el) {
    airlines = airlines.concat(el.airlines);
});

airlines = _.without(_.uniq(airlines), flight.carrier.caption);
var baggageTypeStatuses = {};
var settings = STATE.getSettings();
var passengerTypes = settings && settings.passengerTypes || [];
function getLuggage (type, services) {
    var keys = (type === 'luggage') ? ['freeCabinLuggage', 'paidCabinLuggage'] : ['freeLuggage', 'paidLuggage'];
    segmentsLuggage = _.object(keys, [{}, {}]);

    _.each(_.pick(services, keys), function (value, key) {
        _.each(value, function (val, type) {
            if (val.nil === true) return false;

            var connectNounAndNumral = function (number) {
                var tens = Math.abs(number) % 100;
                var units = number % 10;
                if (tens > 10 && tens < 20) return "other";
                if (units > 1 && units < 5) return "few";
                if (units === 1) return "one";
                return "other";
            }

            var result = {
              description: Formatter.formatLuggageDescription(val)
                // description: (val.pieces ? val.pieces : '') + (val.pieces && val.weight != null ? ' x ' : '') + ((val.weight != null) ? ( + val.weight + ' ' + L10N.get("flightlegs.weight")) : val.pieces ? ' ' + (val.unit || L10N.get("flightlegs.piecesCount." + connectNounAndNumral(val.pieces))) : '')
            };

            if (val.price != null) result.price = Formatter.formatMoney(val.price.amount) + ' ' + Formatter.formatCurrency(val.price.currencyCode);
            if (val.size != null) result.size = val.size != 0 ? L10N.get("avia.upTo") + ' ' + val.size + ' ' + L10N.get("flightlegs.size") : null;

            result.passenger = _.find(passengerTypes, function (el) { return el && el.uid === type; });
            result.paid = ['paidLuggage', 'paidCabinLuggage'].indexOf(key) !== -1;
            segmentsLuggage[key][type] = result;
        });

        if (_.isEmpty(segmentsLuggage[key])) delete segmentsLuggage[key];
    });

    return segmentsLuggage;
}

function checkRefundExchangeFullAllowed (flightFare) {
	return _.every(['refund', 'exchange'], function (type) {
		if (type === 'refund') {
			return flightFare[type].ADULT && _.every([flightFare[type].ADULT.refundableBeforeDeparture, flightFare[type].ADULT.refundableAfterDeparture]);
        } else {
            return flightFare[type].ADULT && _.every([flightFare[type].ADULT.exchangeableBeforeDeparture, flightFare[type].ADULT.exchangeableAfterDeparture]);
        }
    });
}

var issueAllowed = issueAllowed || false;
var approvalAllowed = approvalAllowed || false;
var isHasAdditionalInfo = flightFares.some(function (fare) { return fare.servicesDetails.additionalDetails; });

const checkPaid = function(fare) {
    return Object.keys(fare).reduce(function(prev, cur) {
        if (fare[cur].paid) {
            prev.paid = true;
        }
        return prev;
    }, {paid: false});
}
;
__p += '\n\n';
 if (_.size(flightFares) > 0) { ;
__p += '\n    ';
 _.each(flightFares, function (flightFare, i) {
        const additionalDetails = flightFare.servicesDetails.additionalDetails;
        const showAvailableSeats = flightFare.availableSeats != null;
    ;
__p += '\n        <div class="b-fares-mobile ' +
((__t = ( i === 0 ? 'selected' : '' )) == null ? '' : __t) +
'" data-fare-card="' +
((__t = ( i )) == null ? '' : __t) +
'" data-flight-index="' +
((__t = ( flightFare._flightIndex )) == null ? '' : __t) +
'">\n            <div class="b-fares-mobile__container">\n                ';
 if (flightFare.tariffs.length !== 0) { ;
__p += '\n                    <div class="b-fares-mobile__tariff b-fares-mobile__row">' +
((__t = ( flightFare.tariffs.join(', ') )) == null ? '' : __t) +
'</div>\n                ';
 } ;
__p += '\n                ';
 if (!_.isEmpty(flightFare.servicesDetails.fareBasis) && !_.isEmpty(flightFare.fareBasis)) { ;
__p += '\n                  <div class="b-fares-mobile__fare-basis-wrapper">\n                    <!-- 1-->\n                    ';

                    flightFare.fareBasis.forEach(function (fareBasis, fareBasisIndex) {
                      var fareRule = flightFare.fareRules && flightFare.fareRules[fareBasisIndex] ? Formatter.btoa(flightFare.fareRules[fareBasisIndex] || "") : "";
                      ;
__p += '\n                      <div class="b-fares-mobile__fare-basis b-fares-mobile__row b-avia-offer-preview__fare-rules-open" data-token="' +
((__t = ( flightFare.flightToken )) == null ? '' : __t) +
'" data-fare-rules="' +
((__t = ( fareRule )) == null ? '' : __t) +
'">\n                        ' +
((__t = ( fareBasis )) == null ? '' : __t) +
'\n                      </div>\n                      ';

                    })
                    ;
__p += '\n                  </div>\n                ';
 } ;
__p += '\n                ';
 if (_.isObject(flightFare.seats) && flightFare.seats.count) { ;
__p += '\n                    <div class="b-fares-mobile__seats b-fares-mobile__row">\n                        ' +
((__t = ( flightFare.seats.count )) == null ? '' : __t) +
' ' +
((__t = ( Formatter.getNumEnding(flightFare.seats.count, L10N.get('formatter.endings.place')) )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n                <div class="b-fares-mobile__services">\n                    <div class="b-fares-mobile__baggage b-fares-mobile__row">\n                        ';

                            var baggages = getLuggage('baggage', flightFare.servicesDetails);
                            var cabinLuggageState = !_.isEmpty(baggages.freeLuggage) || !_.isEmpty(baggages.paidLuggage) ? 'FREE' : 'OFF';
                        ;
__p += '\n                        ';
 if (!_.isEmpty(baggages)) { ;
__p += '\n                            <span class="b-fares-mobile__service-icon g-icon-option-baggage-' +
((__t = ( cabinLuggageState === 'FREE' ? 'full' : cabinLuggageState + '-black' )) == null ? '' : __t) +
'"></span>\n                            <div>\n                                ';
 _.each(baggages, function (fare) { ;
__p += '\n                                    ';
 var paidInfo = checkPaid(fare); ;
__p += '\n                                    <div class="b-ticket-luggage-val-block ' +
((__t = ( paidInfo.paid === true ? '' : 'green' )) == null ? '' : __t) +
'">\n                                        <div class="b-ticket-luggage-title ' +
((__t = ( paidInfo.paid === true ? 'red' : 'green' )) == null ? '' : __t) +
'">\n                                            ' +
((__t = ( L10N.get('flightlegs.' + (paidInfo.paid === true ? 'paid' : 'free')) )) == null ? '' : __t) +
':\n                                        </div>\n                                        ';
 _.each(fare, function (p) { ;
__p += '\n                                            <div>\n                                                ';
 if (p.passenger != null && _.size(fare) > 1) { ;
__p +=
((__t = ( p.passenger.caption )) == null ? '' : __t) +
':';
 } ;
__p += '\n                                                ' +
((__t = ( p.description )) == null ? '' : __t) +
' ' +
((__t = ( (p.size != null) ? '(' + p.size + ')' : '' )) == null ? '' : __t) +
'\n                                            </div>\n                                        ';
 }); ;
__p += '\n                                    </div>\n                                ';
 }); ;
__p += '\n                            </div>\n                        ';
 } else { ;
__p += '\n                            <span class="b-fares-mobile__service-icon g-icon-option-baggage-OFF-black"></span>\n                            <span class="b-branded-fares__feature b-feature b-feature--type-negative">' +
((__t = ( L10N.get('Common.no') )) == null ? '' : __t) +
'</span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="b-fares-mobile__luggage b-fares-mobile__row">\n                        ';

                            var luggage = getLuggage('luggage', flightFare.servicesDetails);
                            var luggageState = !_.isEmpty(luggage.freeCabinLuggage) || !_.isEmpty(luggage.paidCabinLuggage) ? 'FREE' : 'OFF';
                        ;
__p += '\n                        ';
 if (!_.isEmpty(luggage)) { ;
__p += '\n                            <span class="b-fares-mobile__service-icon g-icon-option-cabin-luggage-' +
((__t = ( luggageState === 'FREE' ? 'full' : luggageState + '-black' )) == null ? '' : __t) +
'"></span>\n                            <div>\n                                ';
 _.each(luggage, function (fare, key) { ;
__p += '\n                                    ';
 var paidInfo = checkPaid(fare); ;
__p += '\n                                    <div class="b-ticket-luggage-val-block ' +
((__t = ( paidInfo.paid === true ? '' : 'green' )) == null ? '' : __t) +
'">\n                                        <div class="b-ticket-luggage-title ' +
((__t = ( paidInfo.paid === true ? 'red' : 'green' )) == null ? '' : __t) +
'">\n                                            ' +
((__t = ( L10N.get('flightlegs.' + ((paidInfo.paid === true) ? 'paid' : 'free')) )) == null ? '' : __t) +
':\n                                        </div>\n                                        ';
 _.each(fare, function (p) { ;
__p += '\n                                            <div>\n                                                ';
 if (p.passenger != null && _.size(fare) > 1) { ;
__p +=
((__t = ( p.passenger.caption )) == null ? '' : __t) +
':';
 } ;
__p += '\n                                                ' +
((__t = ( p.description )) == null ? '' : __t) +
' ' +
((__t = ( (p.size != null) ? (p.description != 0) ? '(' + p.size + ')' : p.size : '' )) == null ? '' : __t) +
'\n                                            </div>\n                                        ';
 }); ;
__p += '\n                                    </div>\n                                ';
 }); ;
__p += '\n                            </div>\n                        ';
 } else { ;
__p += '\n                            <span class="b-fares-mobile__service-icon g-icon-option-cabin-luggage-OFF-black"></span>\n                            <span class="b-branded-fares__feature b-feature b-feature--type-negative">' +
((__t = ( L10N.get('Common.no') )) == null ? '' : __t) +
'</span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="b-fares-mobile__refund-exchange">\n                        ';

                            var services = flightFare.flight.servicesStatuses;
                            if (services.exchange != null || services.refund != null) {
                                var states = _.compact([(services.exchange || {}).uid, (services.refund || {}).uid]);
                                var state = 'FREE';
                                if ((states[0] === 'OFF' && !states[1]) || (states[0] === 'OFF' && states[0] === states[1])) {
                                    state = 'OFF';
                                }
	    						if (!_.every([flightFare.refund, flightFare.exchange], _.isEmpty) && checkRefundExchangeFullAllowed(flightFare)) {
	    							state = 'full';
                                }
                            }
                        ;
__p += '\n                        <span class="b-fares-mobile__service-icon g-icon-option-refund-exchange-' +
((__t = ( state === 'full' ? state : state + '-black' )) == null ? '' : __t) +
'" style="min-width: 22px;"></span>\n                        <div>\n                            <div class="b-fares-mobile__row">\n                                ' +
((__t = ( require('./b-ticket-refund-exchange.ejs')({ services: {exchange: flightFare.flight.exchange }, flightToken: flightFare.flightToken, airlineCode: null, isList: false, isMobileTemplate: true }) )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                ';
 if (showAvailableSeats) { ;
__p += '\n                    <div class="b-fares-mobile__available-seats">\n                        ' +
((__t = ( require('./b-ticket-available-seats.ejs')({
                            availableSeats: flightFare.availableSeats,
                        }) )) == null ? '' : __t) +
'\n                    </div>\n                ';
 } ;
__p += '\n                <div class="b-fares-mobile__pricing inherit-color">\n                    ' +
((__t = ( require('./b-ticket-pricing.ejs')({
                        price: flightFare.price,
                        isMobileTemplate: true,
                        totalPrice: flightFare.totalPrice
                    }) )) == null ? '' : __t) +
'\n                </div>\n                <div class="b-fares-mobile__travel-policy">\n                    ';
 if ((flightFare.travelPolicyCompliance != null && !_.isEmpty(flightFare.travelPolicyCompliance.rulesApplied)) || flightFare.isTripartiteContractDiscountApplied === true) { ;
__p += '\n                        ';
 if (flightFare.travelPolicyCompliance != null && !_.isEmpty(flightFare.travelPolicyCompliance.rulesApplied)) {
                        _.each(flightFare.travelPolicyCompliance.rulesApplied, function (rule) { ;
__p += '\n                            <div class="b-travel-policy__line">\n                                <span class="b-travel-policy-rule g-icon-travel-policy-rules-' +
((__t = ( rule.action.uid )) == null ? '' : __t) +
'"></span>\n                                <div class="b-travel-policy__description">\n                                    <div class="b-travel-policy__rule-caption">' +
((__t = ( rule.action.caption )) == null ? '' : __t) +
'</div>\n                                    ';
 if (_.size(rule.ruleDetails) > 0) { ;
__p += '\n                                        ';
 _.each(rule.ruleDetails, function(ruleDetail){ ;
__p += '\n                                            <div>' +
((__t = ( _.escape(ruleDetail) )) == null ? '' : __t) +
'</div>\n                                        ';
 }); ;
__p += '\n                                    ';
 } ;
__p += '\n                                </div>\n                            </div>\n                        ';
 }); ;
__p += '\n                        ';
 } ;
__p += '\n                        ';
 if (flightFare.isTripartiteContractDiscountApplied === true) { ;
__p += '\n                            <div class="b-travel-policy__line">\n                                <span class="b-ticket-leg__feature g-icon-features-3D-contract"></span>\n                                <div class="b-travel-policy__description">\n                                    <div class="b-travel-policy__rule-caption">' +
((__t = ( L10N.get('ticket.tripartiteContractDiscount') )) == null ? '' : __t) +
'</div>\n                                </div>\n                            </div>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n                </div>\n            </div>\n        </div>\n    ';
 }); ;
__p += '\n';
 } ;
__p += '\n';

}
return __p
}