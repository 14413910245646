// eslint-disable-next-line banned-modules
'use strict';

import Base64Utils from './base64-utils';

class Formatter {
	static btoa(bin) {
		return Base64Utils.encode(bin);
	}
  
	static atob(asc) {
		return Base64Utils.decode(asc);
	}

	static toCamelCase(str) {
		return str
			.replace(/\s(.)/g, ($1) => { return $1.toUpperCase(); })
			.replace(/\s/g, '')
			.replace(/^(.)/, ($1) => { return $1.toLowerCase(); });
	}

	static formatLuggageDescription(val) {
		if (!val) {
			return '';
		}
		let output = '';
		if (val.pieces != null) {
			output += `${val.pieces} x `;
		}
    
		if (val.weight !== undefined && val.weightUnit !== undefined) {
			output += `${val.weight} ${val.weightUnit.caption || ''}`;
		}
    
		if (val.width !== undefined && val.height !== undefined && val.length !== undefined) {
			output += ` ${val.width}x${val.height}x${val.length}`;
			if (val.dimensionUnit !== undefined) {
				output += ` ${val.dimensionUnit.caption || ''}`;
			}
		} else if (val.dimensionUnit !== undefined && val.dimensionsSum !== undefined) {
			output += ` ${val.dimensionsSum} ${val.dimensionUnit.caption || ''}`;
		}
		return output;
	}

	static formatCurrency(cur, codeOnly = false) {
		let currencyCode = cur;
		if (cur != null) {
			switch (cur) {
				case 'RUB':
					currencyCode = '₽';
					break;
				case 'GEL':
					currencyCode = '₾';
					break;
				case 'KZT':
					currencyCode = '₸';
					break;
				case 'UAH':
					currencyCode = '₴';
					break;
				case 'USD':
					currencyCode = '$';
					break;
				case 'EUR':
					currencyCode = '€';
					break;
				case 'AZN':
					currencyCode = '₼';
					break;
				default:
					currencyCode = cur;
					break;
			}
		}
		if (codeOnly) {
			return currencyCode;
		}
		return `<span class="currency">${currencyCode}</span>`;
	}

	static displayFullName(obj) {
		if (obj != null && typeof obj === 'object') {
			const lang = STATE.getLanguage();
			const fullName = [];
			const firstName = this.displayNotNullValues(lang === 'ru' ? obj.firstName : obj.firstNameLat);
			const secondName = this.displayNotNullValues(lang === 'ru' ? obj.secondName : obj.secondNameLat);
			const middleName = this.displayNotNullValues(lang === 'ru' ? obj.middleName : obj.middleNameLat);
			const lastName = this.displayNotNullValues(lang === 'ru' ? obj.lastName : obj.lastNameLat);
			if (firstName !== '') {
				fullName.push(firstName);
			}
			if (secondName !== '') {
				fullName.push(secondName);
			}
			if (middleName !== '') {
				fullName.push(middleName);
			}
			if (lastName !== '') {
				fullName.push(lastName);
			}
			if (fullName.length === 0 && !_.isEmpty(obj.emails)) {
				fullName.push(obj.emails[0]);
			}
			return fullName.join(' ');
		}
		return '';
	}

	static formatPrice(str) {
		let result = 0;
		if (str != null && str !== '') {
			result = parseFloat(str, 10);
		}
		return result.toFixed(2).replace(/\.?0*$/, '');
	}

	static formatMoney(amount, decimalCount, decimal = '.', thousands = ' ') {
		decimalCount = decimalCount == null ? 0 : decimalCount;
		decimalCount = Math.abs(decimalCount);
		decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

		const negativeSign = amount < 0 ? '-' : '';

		const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
		const j = (i.length > 3) ? i.length % 3 : 0;

		return negativeSign + (j ? i.substr(0, j) + thousands : '') +
				i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
				(decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '');
	}

	static getDecimal(amount, decimal = '.') {
		if (amount == null || typeof amount !== 'string' || amount.indexOf(decimal) === -1) return 0;
		const decimals = Number(amount.split('.')[1]);

		return decimals === 0 ? 0 : decimals.toString().length;
	}

	static displayNotNullValues(str) {
		if (str != null && str.trim() !== '') {
			return str.trim();
		}
		return '';
	}

	static formatDurationInMinutes(duration) {
		const hours = Math.floor(duration / 60);
		const minutes = hours > 0 ? duration - (hours * 60) : duration;
		if (hours === 0) {
			return `${minutes} ${L10N.get('formatter.minutes')}`;
		}
		return `${hours} ${L10N.get('formatter.hours')} ${minutes} ${L10N.get('formatter.minutes')}`;
	}

	static formatDurationFromPattern(duration) {
		if (!duration) {
			return ``;
		}
    
		let durationString = L10N.get('flightlegs.legDurationPattern');

		const durationDay = Math.floor(duration / 1440);
		const durationHours = Math.floor((duration % 1440) / 60);
		const durationMin = duration % 60;

		/*eslint-disable */
		while (true) {
			const block = /\[(.*?)\]/.exec(durationString);

			if (!block) break;
			if (block[1].indexOf('DD') >= 0) {
				if (durationDay === 0) {
					durationString = durationString.replace(block[0], '');
					continue;
				}
				durationString = durationString
						.replace(block[0], block[1].replace('DD', durationDay));
				continue;
			}
			if (block[1].indexOf('HH') >= 0) {
				durationString = durationString
						.replace(block[0], ((durationHours < 10) ? '0' : '') + block[1].replace('HH', durationHours));
				continue;
			}
			if (block[1].indexOf('mm') >= 0) {
				durationString = durationString
						.replace(block[0], ((durationMin < 10) ? '0' : '') + block[1].replace('mm', durationMin));
				continue;
			}
		}
		/*eslint-enable */

		return durationString;
	}

	/*
	*  @param  aEndings Array Массив слов или окончаний для чисел (1, 4, 5),
	*         например ['яблоко', 'яблока', 'яблок']
	*
	*  See also for future implementations:
	*  http://unicode.org/repos/cldr-tmp/trunk/diff/supplemental/language_plural_rules.html
	*  and implementation in pdf.js, file l10n.js
	*/
	static getNumEnding(iNumber, aEndings) {
		let sEnding = null;
		let i = null;
		iNumber %= 100;
		if (iNumber >= 11 && iNumber <= 19) {
			sEnding = aEndings[2];
		} else {
			i = iNumber % 10;
			switch (i) {
				case (1): sEnding = aEndings[0]; break;
				case (2):
				case (3):
				case (4): sEnding = aEndings[1]; break;
				default: sEnding = aEndings[2];
			}
		}
		return sEnding;
	}

	static formatDistance(distance, precision) {
		if (precision == null) {
			precision = 1;
		}

		distance /= 1000;
		return Number(distance.toFixed(precision));
	}

	static formatDayOfWeek(dayOfWeek = '') {
		return `${`${dayOfWeek.charAt(0).toUpperCase()}${dayOfWeek
			.slice(1)
			.toLowerCase()}`.replaceAll('.', '')}.`;
	}
}

// Fix for ProvidePlugin which properly works only with old module definition
/*eslint-disable */
// module.exports = Formatter;
/*eslint-enable */

export default Formatter;
