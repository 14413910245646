module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(flight.legs, function (leg, index) {
	var services = flight.servicesStatuses;

	var segmentFirst = leg.segments[0];
	var segmentLast = leg.segments[leg.segments.length - 1];
	var subjectToGovernmentApproval = _.some(leg.segments, function(s) {
		return s.subjectToGovernmentApproval === true;
	});

	var dateDeparture = new Time(segmentFirst.departureDate);
	var dateArrival = new Time(segmentLast.arrivalDate);

	var opSegmentsInfo = [];
	var opAircraft = [];
	var opFlightNumbers = [];
	var opClassOfService = [];
	var opStops = [];

	var opAirlinesObj = {};
	var airlinesList = [];

	leg.segments.forEach(function(segment, i) {
		if (segment.operatingAirlineCompanyName != null && segment.operatingAirlineCompanyName != flight.carrier.caption) {
			opAirlinesObj[segment.operatingAirlineCompanyName] = true;
		} else if (segment.operatingAirline != null && segment.operatingAirline.uid != flight.carrier.uid) {
			opAirlinesObj[segment.operatingAirline.caption] = true;
		} else if (segment.airline != null) {
			opAirlinesObj[segment.airline.caption] = true;
		}

		opAircraft.push(segment.aircraft && segment.aircraft.caption || '-');
		opFlightNumbers.push(segment.airline && segment.airline.airlineCode + segment.flightNumber);
		opClassOfService.push({
			title: segment.classOfService.caption + (segment.classOfServiceCode != null ? ' (' + segment.classOfServiceCode + ')' : ''),
			tooltip: (segment.servicesDetails && segment.servicesDetails.tariffName) ? segment.servicesDetails.tariffName : '',
		});

		if (i > 0) {
			var segmentDateDeparture = new Time(segment.departureDate);
			var prevStopDuration = new Time(leg.segments[i - 1].arrivalDate);
		 	var stopDuration = segmentDateDeparture.subtract(prevStopDuration.getMoment().toDate().getTime(), 'ms').getMoment().toDate().getTime() / 1000 / 60;

		 	opStops.push(((segment.departureCity || {}).caption || '--') + ' (' + ((segment.departureAirport || {}).uid || '--') + ') - ' + Formatter.formatDurationFromPattern(stopDuration));
		}
        if (segment.airline && segment.airline.airlineCode && segment.airline.codeSystem) {
            airlinesList.push(segment.airline);
        }
	});
    airlinesList = _.uniq(airlinesList, false, function(a) { return a.airlineCode; });

	opAircraft = _.uniq(opAircraft);
	var opAirlines = _.keys(opAirlinesObj);
	if (_.size(opAirlines) === 1 && opAirlines.indexOf(flight.carrier.caption) !== -1) {
		opAirlines = [];
	}

	var techStopInfos = leg.segments.reduce((acc, cur) => {
			if (cur.techStopInfos !== null && cur.techStopInfos.length > 0) {
				_.each(cur.techStopInfos, (stop) => acc.push(stop.location.caption + " (" + stop.location.uid + ") - " + Formatter.formatDurationFromPattern(stop.duration)))
			}
			return acc
		}, [])

	var companyForIcon = flight.carrier && flight.carrier.codeSystem !== 'IATA' ? flight.carrier.airlineCode + '-' + flight.carrier.codeSystem : flight.carrier && flight.carrier.airlineCode || 'noCarrierField';
    var nextDay = dateDeparture.format('D') !== dateArrival.format('D');
	var dateTime = dateDeparture.toShortTime() + '-' + dateArrival.toShortTime();
    var fnLength = _.size(opFlightNumbers);
    var tooltipTariffNames = _.compact(_.map(opClassOfService, function (el) { return el.tooltip; }));
	;
__p += '\n\n	<div class="b-flight-ticket__leg b-leg b-leg-' +
((__t = ( index )) == null ? '' : __t) +
'">\n        ';
 if (flight.legs.length > 1) { ;
__p += '\n            <span class="js-copy-content copy-content">' +
((__t = ( index === 0 ? '\n' + L10N.get('trains.there') + '\n' : '\n' + L10N.get('trains.backward') + '\n' )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.departureDate') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__date .b-leg__date-info > :first-child"></span></span>\n        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('filters.airlines') )) == null ? '' : __t) +
': ' +
((__t = ( _.map(airlinesList, function(el) { return el.caption}).join(', '))) == null ? '' : __t) +
'</span>\n        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('cabinet.orders.direction') )) == null ? '' : __t) +
': (<span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__segments-iata"></span>), <span data-copy-title-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__segments-iata > :first-child"></span> - <span data-copy-title-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__segments-iata > :last-child"></span></span>\n        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('filters.departureDate') + '\\' + L10N.get('filters.arrivalDate') )) == null ? '' : __t) +
': ' +
((__t = ( dateTime )) == null ? '' : __t) +
' ';
 if (nextDay) { ;
__p += '+1&#10;&#13;<span data-copy-title-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__date .b-leg__date-info"></span>';
 } ;
__p += '</span>\n        <span class="js-copy-content copy-content">';
 if (_.size(leg.segments) === 1) { ;
__p += '<span>' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
', ' +
((__t = ( L10N.get('ticket.aircraftNumber') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__flight-info .b-leg__flight-info-number"></span></span>';
 } else if (fnLength > 0) { ;
__p += '<span>' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__flight-info .b-leg__flight-info-number"></span></span>';
 } ;
__p += '</span>\n        ';
 if (leg.segments.length > 1) { ;
__p += '<span class="js-copy-content copy-content">' +
((__t = ( L10N.get('filters.transfersTitle') )) == null ? '' : __t) +
': <span data-copy-title-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__segments-duration-stops"></span></span>';
 } ;
__p += '\n        <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('filters.travelTime') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__segments-duration"></span></span>\n        ';
 if (_.size(opAirlines) > 0) { ;
__p += ' <span class="js-copy-content copy-content"><span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-leg__flight-info-perfom"></span></span> ';
 } ;
__p += '\n        ';
 if (flight.infoMessage) { ;
__p += ' <span class="js-copy-content copy-content">' +
((__t = ( L10N.get('Common.importantInformation') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' b-leg__flight-info-important"></span></span> ';
 } ;
__p += '\n        ';
 if (leg.segments.length === 1) { ;
__p += ' <span class="js-copy-content copy-content"><span>' +
((__t = ( L10N.get('flightlegs.classOfService') )) == null ? '' : __t) +
': <span data-copy-content-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-flight-services__tariff.mobile-hide"></span></span>';
 if (_.size(tooltipTariffNames) > 0) { ;
__p += '(<span data-copy-title-from=".b-leg-' +
((__t = (index)) == null ? '' : __t) +
' .b-flight-services__tariff.mobile-hide"></span>)';
};
__p += '</span> ';
 } ;
__p += '\n\n		<div class="b-leg__company-logo g-icon" style="background-image: url(\'/assets/img/logos/' +
((__t = ( companyForIcon )) == null ? '' : __t) +
'-black.svg\')" data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(flight.carrier.caption) )) == null ? '' : __t) +
'"></div>\n		<div class="b-leg__flight">\n			<div class="b-leg__mobile">\n				<div class="b-leg__mobile-dates">\n					<span class="b-leg__mobile-date-time">\n						' +
((__t = ( dateDeparture.toShortTime() )) == null ? '' : __t) +
'\n					</span>\n					<span class="b-leg__mobile-duration">' +
((__t = ( Formatter.formatDurationFromPattern(leg.duration) )) == null ? '' : __t) +
'</span>\n					<span class="b-leg__mobile-date-time">\n						' +
((__t = ( dateArrival.toShortTime() )) == null ? '' : __t) +
'\n					</span>\n				</div>\n				<div class="b-leg__mobile-route-line"></div>\n				<div class="b-leg__mobile-route-info">\n					<span class="b-leg__mobile-route-location">\n						<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.compact([segmentFirst.departureCity && segmentFirst.departureCity.caption, segmentFirst.departureAirport && segmentFirst.departureAirport.caption]).join(', ') )) == null ? '' : __t) +
'">\n							';
 if (segmentFirst.departureCity) { ;
__p += '\n								' +
((__t = ( segmentFirst.departureCity.uid )) == null ? '' : __t) +
'\n							';
 } ;
__p += '\n						</span>\n					</span>\n					<span class="b-leg__mobile-route-stops">\n						';
 if (leg.segments.length > 1) { ;
__p += '\n							<span>' +
((__t = ( leg.segments.length - 1 )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('flightlegs.stopEnum', leg.segments.length - 1) )) == null ? '' : __t) +
'</span>\n						';
 } else { ;
__p += '\n							<span>' +
((__t = ( L10N.get('flightlegs.direct') )) == null ? '' : __t) +
'</span>\n						';
 } ;
__p += '\n					</span>\n					<span class="b-leg__mobile-route-location">\n						<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.compact([segmentLast.arrivalCity && segmentLast.arrivalCity.caption, segmentLast.arrivalAirport && segmentLast.arrivalAirport.caption]).join(', ') )) == null ? '' : __t) +
'">\n							';
 if (segmentLast.arrivalCity) { ;
__p += '\n								' +
((__t = ( segmentLast.arrivalCity.uid )) == null ? '' : __t) +
'\n							';
 } ;
__p += '\n						</span>\n					</span>\n				</div>\n			</div>\n			<div class="b-leg__date">\n				<span class="b-leg__date-time">\n                    ' +
((__t = ( dateTime )) == null ? '' : __t) +
'\n                </span>\n				<span class="b-leg__date-info" ';
 if (nextDay === true) { ;
__p += ' data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('ticket.arrive') )) == null ? '' : __t) +
': ' +
((__t = ( dateArrival.format('dddd, DD.MM') )) == null ? '' : __t) +
'"';
 } ;
__p += '>\n					<span>' +
((__t = ( dateDeparture.toShortDate() )) == null ? '' : __t) +
'</span>\n					';
 if (nextDay === true) { ;
__p += '\n						<sup class="b-leg__date-next-day">+1</sup>\n					';
 } ;
__p += '\n				</span>\n			</div>\n			<div class="b-leg__flight-info">\n				<span class="b-leg__flight-info-number">\n					';
 if (_.size(leg.segments) === 1) { ;
__p += '\n						<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
'">\n							' +
((__t = ( opFlightNumbers.join(', ') )) == null ? '' : __t) +
',\n						</span>\n						<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('ticket.aircraftNumber') )) == null ? '' : __t) +
'">\n							' +
((__t = ( opAircraft.join(', ') )) == null ? '' : __t) +
'\n						</span>\n					';
 } else if (fnLength > 0) { ;
__p += '\n						';
 _.each(opFlightNumbers, function(fn, i) { ;
__p += '\n							<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( L10N.get('ticket.flightNumber') )) == null ? '' : __t) +
'">\n								' +
((__t = ( fn + ((fnLength - 1 !== i) ? ', ' : '') )) == null ? '' : __t) +
'\n							</span>\n						';
 }) ;
__p += '\n					';
 } ;
__p += '\n				</span>\n				';
 if (_.size(opAirlines) > 0) { ;
__p += '\n					<div class="b-leg__flight-info-perfom">\n						' +
((__t = ( L10N.get('ticket.flightBy') )) == null ? '' : __t) +
': <span class="b-leg__flight-info-perfom-airlines">' +
((__t = ( opAirlines.join(', ') )) == null ? '' : __t) +
'</span>\n					</div>\n				';
 } ;
__p += '\n				';
 if (subjectToGovernmentApproval) { ;
__p += '\n					<div>\n						<strong class="b-leg__flight-info-important">\n							SUBJECT TO GOVERNMENT APPROVAL\n						</strong>\n					</div>\n				';
 } ;
__p += '\n				';
 if (flight.infoMessage) { ;
__p += '\n					<div class="b-leg__flight-info-important">\n						' +
((__t = ( _.escape(flight.infoMessage) )) == null ? '' : __t) +
'\n					</div>\n				';
 } ;
__p += '\n			</div>\n		</div>\n		<div class="b-leg__segments">\n			<div class="b-leg__segments-container">\n				<div class="b-leg__segments-duration">\n					<span class="b-leg__segments-duration-time">' +
((__t = ( Formatter.formatDurationFromPattern(leg.duration) )) == null ? '' : __t) +
'</span>\n				</div>\n				<div class="b-leg__segments-iata">\n					<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.compact([segmentFirst.departureCity && segmentFirst.departureCity.caption, segmentFirst.departureAirport && segmentFirst.departureAirport.caption]).join(', ') )) == null ? '' : __t) +
'">\n						';
 if (segmentFirst.departureAirport) { ;
__p += '\n							' +
((__t = ( segmentFirst.departureAirport.uid )) == null ? '' : __t) +
'\n						';
 } else if (segmentFirst.departureCity) { ;
__p += '\n							' +
((__t = ( segmentFirst.departureCity.caption )) == null ? '' : __t) +
'\n						';
 } ;
__p += '\n					</span>\n						-\n						<span data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.compact([segmentLast.arrivalCity && segmentLast.arrivalCity.caption, segmentLast.arrivalAirport && segmentLast.arrivalAirport.caption]).join(', ') )) == null ? '' : __t) +
'">\n						';
 if (segmentLast.arrivalAirport) { ;
__p += '\n							' +
((__t = ( segmentLast.arrivalAirport.uid )) == null ? '' : __t) +
'\n						';
 } else if (segmentLast.arrivalCity) { ;
__p += '\n							' +
((__t = ( segmentLast.arrivalCity.caption )) == null ? '' : __t) +
'\n						';
 } ;
__p += '\n					</span>\n				</div>\n			</div>\n			<div class="b-leg__segments-container">\n				';
 if (leg.segments.length > 1) { ;
__p += '\n					<span class="b-leg__segments-duration-stops" data-html="true" data-toggle="tooltip" data-placement="bottom" data-original-title="' +
((__t = ( _.map(opStops, function(el) { return '<div class=\'b-leg__segments-duration-stop\'>' + el + '</div>'; }).join('') )) == null ? '' : __t) +
'">' +
((__t = ( leg.segments.length - 1 )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('flightlegs.stopEnum', leg.segments.length - 1) )) == null ? '' : __t) +
'</span>\n				';
 } ;
__p += '\n				';
 if (techStopInfos.length) { ;
__p += '\n					<span class="b-leg__segments-duration-stops" data-html="true" data-toggle="tooltip" data-placement="bottom" data-original-title="' +
((__t = ( _.map(techStopInfos, function(el) { return '<div class=\'b-leg__segments-duration-stop\'>' + el + '</div>'; }).join('') )) == null ? '' : __t) +
'">' +
((__t = ( techStopInfos.length )) == null ? '' : __t) +
' ' +
((__t = ( L10N.getPluralForm('flightlegs.techStopEnum', techStopInfos.length) )) == null ? '' : __t) +
'</span>\n				';
 } ;
__p += '\n			</div>\n		</div>\n		<div class="b-leg__services b-flight-services">\n				';
 if (_.size(leg.segments) === 1) { ;
__p += '\n					<div class="b-flight-services__tariff-wrapper">\n						<span class="b-flight-services__tariff mobile-hide" ';
 if (_.size(tooltipTariffNames) > 0) { ;
__p += ' data-toggle="tooltip" data-placement="bottom" title="' +
((__t = ( _.escape(tooltipTariffNames.join(', ')) )) == null ? '' : __t) +
'"';
 } ;
__p += '>\n							' +
((__t = ( _.map(opClassOfService, function (el) { return el.title; }).join(', ') )) == null ? '' : __t) +
'\n						</span>\n                        <span class="b-flight-services__tariff b-fare-rules__rule-carrier-open action-link desktop-hide" data-token="' +
((__t = ( flightToken )) == null ? '' : __t) +
'" >\n							' +
((__t = ( _.map(opClassOfService, function (el) { return el.title; }).join(', ') )) == null ? '' : __t) +
'\n						</span>\n            <span class="b-fare-rules-group">\n              <!-- 5 -->\n              ';
 if (leg.segments[0].servicesDetails.fareBasis != null) { ;
__p += '\n                ';
 _.keys(leg.segments[0].servicesDetails.fareBasis).forEach(function(fareBasis) { ;
__p += '\n                  ';
 var fareRule = leg.segments[0].servicesDetails.fareRules ? Formatter.btoa(leg.segments[0].servicesDetails.fareRules[fareBasis] || "") : ""; ;
__p += '\n                  <span class="action-link b-fare-rules__rule-carrier-open" data-token="' +
((__t = ( flightToken )) == null ? '' : __t) +
'" data-fare-rules="' +
((__t = (fareRule )) == null ? '' : __t) +
'">\n                    ' +
((__t = ( leg.segments[0].servicesDetails.fareBasis[fareBasis] || '' )) == null ? '' : __t) +
'\n                  </span>\n                ';
 }) ;
__p += '\n              ';
 } ;
__p += '\n            </span>\n					</div>\n				';
 } ;
__p += '\n				' +
((__t = ( require('../b-ticket-services.ejs')({
					leg: leg,
                    legIndex: index,
					flight: flight,
                    copyExchangeRefund: false,
				}) )) == null ? '' : __t) +
'\n		</div>\n	</div>\n';
 if (flight.legs.length - 1 === index) { ;
__p += '\n    <div class="b-leg__mobile-controls">\n        <span class="b-leg__mobile-branded-fares">' +
((__t = ( L10N.get('flightlegs.selectBrandedFares') )) == null ? '' : __t) +
'</span>\n				';
 if (flight.isMultiTicket) { ;
__p += '\n					<div\n						data-toggle="tooltip"\n						data-placement="top"\n						data-html="true"\n						data-original-title="<div>' +
((__t = ( L10N.get('multiticket_hint') )) == null ? '' : __t) +
'</div>"\n						style="\n						background: #4A5767;\n						color: white;\n						padding: 2px 6px;\n						border-radius: 3px;\n						white-space: nowrap;"\n					>\n						Multi-Ticket\n					</div>\n				';
 } ;
__p += '\n        <span class="b-leg__mobile-expand">' +
((__t = ( L10N.get('flightlegs.flightDetails') )) == null ? '' : __t) +
'</span>\n    </div>\n';
 } ;
__p += '\n\n';
 }); ;
__p += '\n';

}
return __p
}