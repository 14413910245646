import { Base64 } from 'js-base64';

class Base64Utils {
	static encode(bin) {
		return Base64.encode(bin);
	}

	static decode(asc) {
		return Base64.decode(asc);
	}
}

export default Base64Utils;
