// eslint-disable-next-line banned-modules
'use strict';

import './style.less';

import BaseView from '@/classes/base.view';
import template from './template.ejs';

import $ from 'jquery';
import * as pdflib from 'pdfjs-dist';
import Formatter from '../../../utils/formatter';

export default BaseView.extend({

	template,

	ui: {
		zoomOut: '.js-pdf-zoom-out',
		zoomIn: '.js-pdf-zoom-in',
	},

	events: {
		'change .js-pdf-select-zoom': 'changeZoom',
		'click .js-pdf-zoom-in': 'zoomIn',
		'click .js-pdf-zoom-out': 'zoomOut',
	},

	pdfs: [],

	minScale : 0.5,

	maxScale : 2.0,

	currentScale: 1.0,

	defaultScale: 1.0,

	frame: null,

	initialize() {
		const {pdfSource} = this.options;

		// const PdfjsWorker = require('pdfjs-dist/build/pdf.worker.entry');
		pdflib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdflib.version}/pdf.worker.min.js`;

		this.pdfSource = this.convertDataURIToBinary(pdfSource);
		this.pdfDocument = pdflib.getDocument({
			data: this.pdfSource,
		});

		this.pdfs = [];
		this.render();
	},

	render(...args) {
		this.pdfDocument.promise.then((pdf) => {
			this.pdfs.push(pdf);
			BaseView.prototype.render.apply(this, args);
			this.showPages();
		});
	},

	getPagesCount() {
		return this.pdfs.reduce((sum, currenDoc) => (currenDoc.numPages ? sum + currenDoc.numPages : sum + 1), 0);
	},

	showPages() {
		const _t = this;
		if (this.__showPagesInProgress) {
			this.__showPagesCallSkipped = true;
			return;
		}
		this.__showPagesInProgress = true;

		const pagesToRender = this.getPagesCount();
		let pagesShowed = 0;

		this.pdfs.forEach((doc) => {
			if (doc.numPages) {
				_.times(doc.numPages, (pageNum) => {
					const canvas = this.$el.find('.js-doc-canvas').get(pagesShowed + pageNum);

					doc.getPage(pageNum + 1).then((page) => {
						if (page != null) {
							const viewport = page.getViewport({
								scale: _t.currentScale,
							});
							const context = canvas.getContext('2d');

							canvas.height = viewport.height;
							canvas.width = viewport.width;

							const renderContext = {
								canvasContext: context,
								viewport,
							};

							page.render(renderContext).promise.then(() => {
								if (page.pageNumber === pagesToRender) {
									this.__showPagesInProgress = false;

									if (this.__showPagesCallSkipped === true) {
										this.__showPagesCallSkipped = false;
										window.setTimeout(() => this.showPages(), 200);
									}
								}
							});
						}
					});
				});

				pagesShowed += doc.numPages;
			}
		});
	},

	// UTILS
	convertDataURIToBinary(base64) {
		const raw = Formatter.atob(base64.replace(/(\r\n|\n|\r)/gm, ''));
		const rawLength = raw.length;
		const array = new Uint8Array(new ArrayBuffer(rawLength));

		for (let i = 0; i < rawLength; i++) {
			array[i] = raw.charCodeAt(i);
		}

		return array;
	},

	disableEnableButton($elm, enable) {
		$elm.attr('disabled', enable !== true);
	},

	zoomButtonsDisableEnable(current, min, max) {
		if (current <= min) {
			this.disableEnableButton($(this.ui.zoomOut), false);
		} else {
			this.disableEnableButton($(this.ui.zoomOut), true);
		}

		if (current >= max) {
			this.disableEnableButton($(this.ui.zoomIn), false);
		} else {
			this.disableEnableButton($(this.ui.zoomIn), true);
		}
	},

	print() {
		if (this.frame != null && this.frame.length > 0) {
			this.frame.detach();
		}

		this.frame = $('<iframe style="padding:0; margin:0; border: none;"></iframe>');

		const taskInfo = {
			_renderTasks: [],
			abort: function abort() {
				this._renderTasks.forEach(task => task.cancel());
			},
		};

		const printMarginMm = 5;
		// A4 is 210mm x 297mm, but we need to leave some space for print margins
		const a4Width = `${(210 - (printMarginMm * 2))}mm`;
		const a4Height = `${(297 - (printMarginMm * 2))}mm`;
		const viewScale = 3;
		const printScale = 1.33 * viewScale;
		const getPagesPromises = [];
		const renderPromises = [];
		const buffer = [];

		this.frame.get(0).onload = () => {
			const frameWin = this.frame.get(0).contentWindow;

			this.pdfs.forEach((doc) => {
				if (doc.numPages) {
					_.times(doc.numPages, (pageNum) => {
						getPagesPromises.push(doc.getPage(pageNum + 1));
					});
				}
			});

			Promise.all(getPagesPromises).then((args) => {
				args = _.filter(args, (e) => !_.isEmpty(e));

				args.forEach((page) => {
					if (page.getViewport != null) {
						let viewport = page.getViewport({
							scale: printScale,
							rotation: 0,
						});
						if (viewport.height < viewport.width) {
							viewport = page.getViewport({
								scale: printScale,
								rotation: 90,
							});
						}

						const canvasWrapper = frameWin.document.createElement('div');
						if (page.pageNumber > 0) {
							if (window.navigator.userAgent.indexOf('Mozilla') === -1) {
								canvasWrapper.style.pageBreakBefore = 'always';
							}
							canvasWrapper.style.pageBreakInside = 'avoid';
						}

						canvasWrapper.style.width = a4Width;
						canvasWrapper.style.height = a4Height;
						canvasWrapper.style.overflow = 'hidden';
						frameWin.document.body.appendChild(canvasWrapper);

						const canvas = $('<canvas></canvas>').get(0);
						canvas.width = viewport.width;
						canvas.height = viewport.height;

						const context = canvas.getContext('2d');
						context.save();
						context.fillStyle = 'rgb(255, 255, 255)';
						context.fillRect(0, 0, viewport.width, viewport.height);
						context.restore();

						const renderContext = {
							canvasContext: context,
							intent: 'print',
							viewport,
						};

						const canvasImage = frameWin.document.createElement('img');
						const widthRatio = viewport.width / canvasWrapper.offsetWidth;
						const heightRatio = viewport.height / canvasWrapper.offsetHeight;

						if ((widthRatio > 1 || heightRatio > 1) && widthRatio > heightRatio) {
							canvasImage.style.width = a4Width;
						} else {
							canvasImage.style.height = a4Height;
						}
						canvasWrapper.appendChild(canvasImage);

						const renderTask = page.render(renderContext).promise;
						taskInfo._renderTasks.push(renderTask);

						renderPromises.push(renderTask);
						buffer.push({
							image: canvasImage,
							type: 'PDF',
							canvas,
						});
					}
				});

				Promise.all(renderPromises).then(() => {
					buffer.forEach((item) => {
						if (item.type === 'PDF') {
							item.image.src = item.canvas.toDataURL('image/png');
						}
					});

					// we need to use defer since blocking sync XHR during microtask execution will be disabled in Chrome.
					// See https://www.chromestatus.com/features/5647113010544640
					_.defer(() => {
						const iframeCleanup = () => {
							if (this.frame != null && this.frame.length > 0) {
								this.frame.detach();
							}

							this.frame = null;
							this.frameWin = null;
						};
						const printStart = new Date().getTime();
						const userAgent = window.navigator.userAgent;
						frameWin.focus();
						if (userAgent.indexOf('MSIE ') !== -1 || userAgent.indexOf('Trident/') !== -1 || userAgent.indexOf('Edge/') !== -1) {
							frameWin.document.execCommand('print', false, null);
						} else {
							frameWin.print();
						}

						const printEnd = new Date().getTime();
						if (printEnd - printStart > 1000) { // hack to check if JS was actually stopped by calling print()
							iframeCleanup();
						} else {
							$(document.body).one('click', iframeCleanup);
						}
					});
				});
			}).catch((e) => { // Не удалять, проброс исключения из PDFJS promise
				setTimeout(() => {
					throw e;
				});
			});

			this.frameWin = frameWin;
		};

		$('body').append(this.frame);
		this.frame.contents().find('head').html(`
		<style>
			@media print{@page {size: A4 portrait; margin: ${printMarginMm}mm}};
			body {-webkit-transform: translate3d(0,0,0);-moz-transform: translate3d(0,0,0);-ms-transform: translate3d(0,0,0);transform: translate3d(0,0,0);}
		</style>`);

		this.frame.contents().find('body').css({
			margin: 0,
			padding: 0,
		});
	},

	// EVENTS
	changeZoom(e) {
		const $target = $(e.target);
		const value = $target.find('option:selected').val();

		if (value === 'auto') {
			this.currentScale = this.defaultScale;
		} else {
			this.currentScale = parseFloat(value);
		}

		this.zoomButtonsDisableEnable(this.currentScale, this.minScale, this.maxScale);
		this.showPages();
	},

	zoomOut() {
		this.currentScale -= 0.1;
		this.zoomButtonsDisableEnable(this.currentScale, this.minScale, this.maxScale);
		if (this.currentScale < this.minScale) {
			this.currentScale = this.minScale;
		}
		$('.js-pdf-select-zoom').val('auto');
		this.showPages();
	},

	zoomIn() {
		this.currentScale += 0.1;
		this.zoomButtonsDisableEnable(this.currentScale, this.minScale, this.maxScale);
		if (this.currentScale > this.maxScale) {
			this.currentScale = this.maxScale;
		}
		$('.js-pdf-select-zoom').val('auto');
		this.showPages();
	},

});
