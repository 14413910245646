// eslint-disable-next-line banned-modules
'use strict';

import './style.less';
import '../b-additional-details/styles.less';
import BaseView from '@/classes/base.view';
import template from './template.ejs';
import exchangeTemplate from './b-ticket-refund-exchange.ejs';
import brandedFaresTemplate from './b-branded-fares.ejs';
import standartFaresTemplate from './b-standart-fares.ejs';
import MobileOfferPreview from './b-ticket-offer-preview-mobile/index';
import GlUl from '@/utils/global-utils';
import AdditionalDetailsTemplate from '@/blocks/elements/b-additional-details/template.ejs';

import ExchangeModalView from '@/blocks/elements/b-exchange-modal/index';

import axios from 'axios';
import $ from 'jquery';
import STATE from '../../../classes/application-state';
import Formatter from '../../../utils/formatter';

export default BaseView.extend({

	template,

	tagName: 'article',

	className: 'b-ticket b-ticket__visible',

	events: {
		'click .b-flight-ticket__expand': 'open',
		'click .b-flight-ticket__expand-branded-fares': 'openBrandedFares',
		'click .b-flight-price__button': 'select',
		'click .b-flight-price__button--exchange': 'openExchangeModal',
		'click .b-branded-fares__select': 'selectBrandedFare',
		'click .b-branded-fares__additionalDetails': 'showAdditionalDetails',
		'click .b-standart-fares__select': 'selectBrandedFare',
		'click .b-branded-fares__select--offer': 'selectBrandedFareOffer',
		'click .b-standart-fares__select--offer': 'selectBrandedFareOffer',
		'click .b-flight-price__branded-fares': 'showBrandedFares',
		'click .b-leg__mobile-branded-fares': 'showBrandedFaresMobile',
		'click .b-branded-fares__slide-left': 'slideLeft',
		'click .b-branded-fares__slide-right': 'slideRigth',
		'click .b-flight-price__button--offer': 'addOffer',
		'click .b-fare-rules__rule-carrier-open': 'openRuleCarrier',
		'click .b-flight-services__rule-carrier-open': 'openRuleCarrier',
		'click .b-leg__mobile-expand': 'showMobileOfferPreview',
		'click .js-select-all-offers': 'addAllFaresOffers',
	},

	initialize() {
		this.approvalAllowed = this.options.approvalAllowed;
		this.issueAllowed = this.options.issueAllowed;

		this.isExchange = this.options.isExchange;
		this.exchangeParameters = this.options.exchangeParameters;
		this.parent = this.options.parent;

		if (this.options.renderQueue == null) {
			window.setTimeout(() => {
				this.render();
			}, 0);
		} else {
			this.options.renderQueue.push(() => this.render());
		}

		if (this.options.classes != null && this.options.classes !== '') {
			this.options.classes.split(' ').forEach(cl => {
				this.$el.get(0).classList.add(cl);
			});
		}

		this.listenTo(this.model, 'change:visibility', (model, value) => {
			const $container = (this.$el.is('article')) ? this.$el : this.$el.parent('article');

			if (value) {
				$container.addClass('b-ticket__visible');
			} else {
				$container.removeClass('b-ticket__visible');
			}
		});
	},

	adjustMobileTemplate(matches) {
		if (!matches) {
			/*
				Кейс из IBECORP-7146.
				Нужно скрыть открытый оффер, когда происходит ресайз окна из мобильного варианта в десктопный.
			*/
			if (this?.aviaOfferPreview) {
				const lLayout = $('.l-layout');
				if (lLayout.hasClass('show-offer-preview')) {
					STATE.hideOfferPreview();
				}
			}
			return;
		}
			
		if (this.$el.parents('.b-ticket__fares-is-open').length > 0) {
			this.showBrandedFares();
			this.showBrandedFaresMobile();
		}
	},

	// ------------------------- IBECORP-3315
	existMandatoryField() {
		const flight = this.model.get(`flight`);
		const missingMandatoryFieldMap = {
			flightInfo: {
				gds: flight.gds.uid,
				gdsAccountName: flight.gdsAccountName,
			},
		};
		let isAllMandatoryExist = true;

		if (flight.carrier == null) {
			missingMandatoryFieldMap.carrier = {
				caption: 'Missing required field "carrier" ',
			};
			isAllMandatoryExist = false;
		}
		for (let i = 0; i < flight.legs.length; i++) {
			const leg = flight.legs[i].segments;

			for (let j = 0; j < leg.length; j++) {
				const segment = leg[j];

				const segmentMandatoryFields = ['aircraft', 'arrivalCity', 'arrivalAirport', 'departureCity', 'departureAirport'];

				for (let k = 0; k < segmentMandatoryFields.length; k++) {
					const field = segmentMandatoryFields[k];
					if (segment[field] == null) {
						missingMandatoryFieldMap[field] = {
							flightNumber: `${segment.airline && segment.airline.uid || ''}${segment.flightNumber}`,
							caption: `Missing required field "${field}" `,
						};
						if (field === 'arrivalCity') missingMandatoryFieldMap[field].missingAirport = segment.arrivalAirport.caption;
						if (field === 'departureCity') missingMandatoryFieldMap[field].missingAirport = segment.departureAirport.caption;
						isAllMandatoryExist = false;
					}
				}
				if (!isAllMandatoryExist) this.model.set('missingMandatoryFieldMap', missingMandatoryFieldMap);
				return isAllMandatoryExist;
			}
		}
		return false;
	},

	// IBECORP-3315 -------------------------

	render(...args) {
		try {
			BaseView.prototype.render.apply(this, args);
		} catch (e) {
			// ------------------------- IBECORP-3315
			if (!this.existMandatoryField()) {
				const fMap = this.model.get('missingMandatoryFieldMap');
				let errorMessage = '';
				_.each(_.keys(fMap), (field) => {
					switch (field) {
						case 'flightInfo':
							errorMessage += `Reservation system: name: ${fMap.flightInfo.gdsAccountName}, uid: ${fMap.flightInfo.gds}\n\n`;
							break;
						case 'arrivalCity':
						case 'departureCity':
							errorMessage +=
								`${fMap[field].caption} for flight ${fMap[field].flightNumber}
								Airport city not defined "${fMap[field].missingAirport}" in the directory of geographical points \n\n`;
							break;
						default:
							errorMessage += fMap[field].flightNumber ?
								`${fMap[field].caption} for flight ${fMap[field].flightNumber} \n\n`
								:
								`${fMap[field].caption} \n\n`;
							break;
					}
				});
				const error = window.ErrorHandler.getLineException(e.stack);
				window.dispatchEvent(new CustomEvent('mergeDictionaryErrors', {
					detail: {
						errorMessage,
						stack: e.stack,
					},
				}));
				window.ErrorHandler.callbackErrorHandler(_.extend({}, error, {
					message: errorMessage,
					error: {
						stack: e.stack,
						isSilentError: true,
					},
				}));

				// IBECORP-3315 -------------------------
			} else {
				if (window.ErrorHandler != null) {
					const error = window.ErrorHandler.getLineException(e.stack);
					window.ErrorHandler.callbackErrorHandler(_.extend({}, error, {
						message: e.message,
						error: {
							stack: e.stack,
							isSilentError: true,
						},
					}));
				}
			}
		}
	},

	loadBrandedFares(flights) {
		this.flightFaresResult = flights;

		const { flight: fl = {} } = _.first(flights);
		this.legsCount = _.size(fl.legs);
		this.isRoundTrip = (() => {
			const firstLeg = _.first(fl.legs);
			const lastLeg = _.last(fl.legs);

			return (
				this.legsCount === 2 &&
				firstLeg && lastLeg &&
				((_.first(firstLeg.segments).departureCity || {}).uid === (_.last(lastLeg.segments).arrivalCity || {}).uid) &&
				((_.last(firstLeg.segments).arrivalCity || {}).uid === (_.first(lastLeg.segments).departureCity || {}).uid)
			);
		})();
		this.isComplexTrip = this.legsCount >= 2 && !this.isRoundTrip;

		if (this.isComplexTrip) {
			this.$el.addClass('is-complex-ticket');
		} else if (this.isRoundTrip) {
			this.$el.addClass('is-roundtrip-ticket');
		}

		let flightFares = [];
		_.each(flights, (el, i) => {
			const {flight = {}} = el;

			const flightFareEl = {
				availableSeats: flight?.availableSeats,
				tariffs: [],
				airlines: [],
				classOfService: {},
				price: flight.price,
				totalPrice: flight.price.total || {},
				exchange: flight.exchange || {},
				refund: flight.refund || {},
				seats: _.sortBy(flight.seats || [], (s) => s.count)[0],
				servicesDetails: {},
				servicesDetailsView: [],
				flight: _.pick(flight, 'servicesStatuses', 'refund', 'exchange'),
				vendorCommissionsData: flight.vendorCommissionsData,
				flightToken: el.flightToken,
				travelPolicyCompliance: flight.travelPolicyCompliance,
				isTripartiteContractDiscountApplied: flight.isTripartiteContractDiscountApplied,
				_flightIndex: i,
			};

			_.each(flight.legs, (l, j) => {
				const legFare = _.extend({}, flightFareEl, {
					leg: l,
					_legIndex: j,
				});

				const leg = l;
				const segment = leg.segments[0];

				legFare.airlines = flightFareEl.airlines.concat(_.compact([
					segment.airline && segment.airline.caption,
					segment.operatingAirline && segment.operatingAirline.caption,
					segment.operatingAirlineCompanyName,
				]));

				legFare.tariffs.push(segment.servicesDetails.tariffName);
				legFare.fareBasis = [];
				_.each(leg.segments, (s) => {
					legFare.fareBasis.push(_.values(s.servicesDetails.fareBasis));
				});
				legFare.fareRules = [];
				_.each(leg.segments, (s) => {
					legFare.fareRules.push(_.values(s.servicesDetails.fareRules));
				});

				legFare.servicesDetails = segment.servicesDetails;
				legFare.servicesDetailsView = segment.servicesDetailsView;
				legFare.classOfService = segment.classOfService;

				legFare.airlines = _.uniq(legFare.airlines);
				legFare.tariffs = _.chain(legFare.tariffs)
					.uniq()
					.compact()
					.value();

				if (!flightFares[j]) {
					flightFares[j] = [];
				}
				flightFares[j].push(legFare);
			});

			flightFares = _.map(flightFares, (fares) => _.sortBy(fares, (f) => {
				return parseInt(f.totalPrice.amount, 10) || 0;
			}));
		});

		this.flightFares = {
			brandedFares: _.map(flightFares, (el) => _.filter(el, (f) => !_.isEmpty(f.tariffs))),
			standartFares: _.map(flightFares, (el) => _.filter(el, (f) => _.isEmpty(f.tariffs))),
		};

		// If get branded and non branded fares merge them and show in block for branded tariffs (columns)
		if (!_.isEmpty(this.flightFares.standartFares[0]) && !_.isEmpty(this.flightFares.brandedFares[0])) {
			this.merged = [];

			const maxLength = this.flightFares.standartFares.length > this.flightFares.brandedFares.length
				? this.flightFares.standartFares.length
				: this.flightFares.brandedFares.length;

			for (let i = 0; i < maxLength; i++) {
				const mergedFares = [
					...this.flightFares.standartFares[i] || {},
					...this.flightFares.brandedFares[i] || {}];
				this.merged.push(mergedFares);
			}

			this.merged.forEach((mergedFares) => {
				mergedFares.sort((a, b) => {
					if (parseInt(a.totalPrice.amount) < parseInt(b.totalPrice.amount)) return -1;
					if (parseInt(a.totalPrice.amount) > parseInt(b.totalPrice.amount)) return 1;
					return 0;
				});
			});

			this.flightFares.brandedFares = this.merged;
			this.flightFares.standartFares = [];
		}
	},

	// EVENTS
	addOffer(e, offer) {
		if (e != null) {
			e.preventDefault();
		}

		if (this.options.parent != null && _.isFunction(this.options.parent.addOffer)) {
			const offerToSend = offer || {
				flight: this.model.get('flight'),
				flightToken: this.model.get('flightToken'),
			};
			this.options.parent.addOffer.call(this.options.parent, e, offerToSend);
		}
	},

	addAllFaresOffers(e) {
		if (e) e.preventDefault();
		const $target = $(e.currentTarget);
		let $btns = this.$el.find('.b-branded-fares__select--offer');
		$btns.push(this.$el.find('.b-flight-price__button--offer'));
		if ($target.hasClass('b-ticket-offer__cancel')) {
			$target.removeClass('b-ticket-offer__cancel');
			$btns = this.$el.find('.b-ticket-offer__cancel');
			$target.tooltip({title: L10N.get('ticket.removeAll')});
		} else {
			$target.addClass('b-ticket-offer__cancel');
			$target.tooltip({title: L10N.get('ticket.selectAll')});
		}

		_.each($btns, (b) => {
			this.$el.find(b).click();
		});
	},

	slideLeft(e) {
		if (e != null) {
			e.preventDefault();
		}
		this.checkBrandedFaresContainer(false);
	},

	slideRigth(e) {
		if (e != null) {
			e.preventDefault();
		}
		this.checkBrandedFaresContainer(true);
	},

	checkBrandedFaresContainer(direction) {
		const deffer = direction == null ? _.defer : (cb) => cb();

		_.each(this.flightFares.brandedFares, (bf, i) => {
			deffer(() => this._checkBrandedFaresContainer(direction, bf, i));
		});

		if (this.flightFares.brandedFares.length >= 2) {
			deffer(() => this._checkBrandedFaresContainer(
				direction,
				_.last(this.flightFares.brandedFares),
				this.flightFares.brandedFares.length,
			));
		}
	},

	_checkBrandedFaresContainer(direction, brandedFares, bfIndex) {
		const withoutAnimation = this.flightFares.brandedFares.length >= 2;

		if (!_.isEmpty(brandedFares) && (!this.faresTransition || withoutAnimation)) {
			const $container 	= this.$el.find(`.b-flight-ticket__branded-fares > [data-flight-index="${bfIndex}"]`);

			let useAbsoluteWidth = false;
			if (!$container.is(':visible')) {
				useAbsoluteWidth = true;
				$container.css({ position: 'absolute', visibility: 'hidden', display: 'block' });
			}

			if (direction == null) {
				$container.find('.action-columns .b-branded-fares__column').hide();
			}

			const $leftBtn 		= $container.find('.b-branded-fares__slide-left');
			const $rightBtn 	= $container.find('.b-branded-fares__slide-right');
			const $flightTicket = $container.parents('.b-ticket__fares-is-open');
			let width;
			if (_.size($flightTicket) > 0) {
				const windowScrollTop = $(window).scrollTop();
				this.openBrandedFares(null, true);
				width = ($flightTicket.width() - 40) - $container.find('.b-branded-fares__column--type-caption').width();
				this.openBrandedFares(null, true);
				$(window).scrollTop(windowScrollTop);
			} else {
				width = $container.width() - $container.find('.b-branded-fares__column--type-caption').width();
			}

			if (direction == null) {
				$container.find('.action-columns .b-branded-fares__column').show();
			}

			if (useAbsoluteWidth === true) {
				$container.css({ position: '', visibility: '', display: '' });
			}

			const ANIMATION_SPEED = 300;

			let activeCols 	= 1;
			const windowWidth = window.innerWidth || screen.width;
			switch (true) {
				case windowWidth >= 1440:
					activeCols = 5;
					break;
				case windowWidth >= 1281:
					activeCols = 3;
					break;
				case windowWidth >= 962:
					activeCols = 2;
					break;
			}

			const cellWidth 	= 100 / activeCols;
			const cellWidthPX 	= width * (cellWidth / 100);

			$leftBtn.removeClass('disabled');
			$rightBtn.removeClass('disabled');

			if (this.activeBFCols == null || this.activeBFCols.end === 0) {
				this.activeBFCols = {
					start: 0,
					end: (activeCols > brandedFares.length) ? brandedFares.length : activeCols,
				};
			} else if ((this.activeBFCols.end - this.activeBFCols.start) !== activeCols) {
				this.activeBFCols.end = ((this.activeBFCols.start + activeCols) > brandedFares.length) ?
					brandedFares.length : (this.activeBFCols.start + activeCols);
			}

			if ((this.activeBFCols.end - this.activeBFCols.start) < activeCols && this.activeBFCols.start > 0) {
				this.activeBFCols.start -= (activeCols - (this.activeBFCols.end - this.activeBFCols.start));
			}

			if (this.activeBFCols.start < 0) {
				this.activeBFCols.start = 0;
			}

			if (direction != null) {
				if (direction === true && this.activeBFCols.end < brandedFares.length) {
					this.activeBFCols.start++;
					this.activeBFCols.end++;
				} else if (direction === false && this.activeBFCols.start > 0) {
					this.activeBFCols.start--;
					this.activeBFCols.end--;
				} else {
					direction = null;
				}
			}

			const $mainContainer = $container.find('.b-branded-fares__container .action-columns');

			let animation = Promise.resolve();
			if (direction != null) {
				if (withoutAnimation) {
					$mainContainer.css({
						left: `${((direction === true) ? '-' : '') + cellWidthPX}px`,
					});
				} else {
					animation = $mainContainer.animate({
						left: `${((direction === true) ? '-' : '') + cellWidthPX}px`,
					}, { duration: ANIMATION_SPEED, queue: false }).promise();
					this.faresTransition = true;
				}
			}

			animation.then(() => {
				const $columnCaption = $container.find('.b-branded-fares__column--type-caption');

				for (let i = 0; i < $columnCaption.find('.b-branded-fares__row').length; i++) {
					const $rows = $container.find(`.b-branded-fares__column .b-branded-fares__row:nth-child(${i + 1})`);
					const maxHeight = Math.max.apply(null, $rows.map((index, el) => $(el).outerHeight(true)));

					$rows.css('minHeight', '').css({minHeight: (maxHeight < 90) && ($rows.css('minHeight') !== 'auto') ? 90 : maxHeight});
				}

				_.each($mainContainer.find('.b-branded-fares__column'), (column, i) => {
					const $column = $(column);

					if (i < this.activeBFCols.start - 1) {
						$column.hide();
					} else if (i === this.activeBFCols.start - 1) {
						$column.show().addClass('action-cell').css('left', `-${(this.activeBFCols.start - i) * cellWidthPX}px`);
					} else if (i === this.activeBFCols.start) {
						$column.show().removeClass('action-cell').css('left', '');
					} else if (i === this.activeBFCols.end - 1) {
						$column.show().removeClass('action-cell').css('right', '');
					} else if (i >= this.activeBFCols.end) {
						$column.show().addClass('action-cell').css('right', `-${(i - this.activeBFCols.end + 1) * cellWidthPX}px`);
					} else {
						$column.show().css({
							left: '',
							right: '',
						}).removeClass('action-cell');
					}

					if (direction == null) {
						$column.css('width', `${cellWidthPX}px`);
					}
				});

				if (direction != null) {
					$mainContainer.css('left', '');
				}
				this.faresTransition = false;
			});

			if (this.activeBFCols.start === 0) {
				$leftBtn.addClass('disabled');
			}

			if (this.activeBFCols.end === brandedFares.length) {
				$rightBtn.addClass('disabled');
			}

			if (brandedFares.length <= activeCols) {
				$container.addClass('without-arrows');
			} else {
				$container.removeClass('without-arrows');
			}
		}
	},

	open(e) {
		if (e != null) {
			e.preventDefault();
		}

		if (this.$el.parent('article').hasClass('b-ticket__is-open')) {
			this.$el.parent('article').removeClass('b-ticket__is-open');
			this.$el.parent()
				.find('.b-flight-ticket__expand-container')
				.stop()
				.slideUp();
		} else {
			this.$el.parent('article').addClass('b-ticket__is-open');
			this.$el.parent()
				.find('.b-flight-ticket__expand-container')
				.stop()
				.slideDown();
		}
	},

	openBrandedFares(e, useCssDisplay = false) {
		if (e != null) {
			e.preventDefault();
		}

		const elements = [];

		if (!_.every(this.flightFares.standartFares, _.isEmpty)) {
			elements.push('.b-flight-ticket__standart-fares');
		}
		if (!_.every(this.flightFares.brandedFares, _.isEmpty)) {
			elements.push('.b-flight-ticket__branded-fares');
		}

		if (_.isEmpty(elements)) {
			return this;
		}

		const $article = this.$el.parent('article');
		const isFaresOpen = $article.hasClass('b-ticket__fares-is-open');

		if (useCssDisplay) {
			if (isFaresOpen) {
				$article.removeClass('b-ticket__fares-is-open');
				this.$el.parent()
					.find(elements.join(','))
					.css({display: 'none'});
			} else {
				$article.addClass('b-ticket__fares-is-open');
				this.$el.parent()
					.find(elements.join(','))
					.css({display: 'block'});
			}
			return this;
		}

		if (isFaresOpen) {
			$article.removeClass('b-ticket__fares-is-open');
			this.$el.parent()
				.find(elements.join(','))
				.stop()
				.slideUp();
		} else {
			$article.addClass('b-ticket__fares-is-open');
			this.$el.parent()
				.find(elements.join(','))
				.stop()
				.slideDown();
		}

		return this;
	},

	showMobileOfferPreview(e) {
		if (STATE.checkViewport('(max-width: 768px)')) {
			if (e != null) {
				if (_.size($(e.target).parents('.b-flight-ticket__price'))) return;
				if (_.size($(e.target).parents('.b-leg__services'))) return;
			}
			const lLayout = $('.l-layout');

			if (!lLayout.hasClass('show-offer-preview')) {
				if (this.aviaOfferPreview == null) {
					this.aviaOfferPreview = new MobileOfferPreview(Object.assign({}, {
						...this.options,
						parent: this,
						pageView: this.options.parent,
						ticketYPosition: window.pageYOffset,
					}));
				}
				this.aviaOfferPreview.options.offerSelected = (_.size(this.$el.find('.b-ticket-offer__cancel')) > 0);

				this.options.parent.ui.sidebar.find('.l-offer-preview-container').empty().append(this.aviaOfferPreview.render().$el);
				lLayout.addClass('show-offer-preview');
				STATE.setIsAviaOfferPreviewOpened(true);
			}
		}
	},

	select(e, flightToken) {
		if (e != null) {
			e.preventDefault();
		}

		if (this.isExchange) {
			this.exchangeModalView = new ExchangeModalView({
				flightToken: flightToken || this.model.get('flightToken'),
				selectedFlight: this.model.get('flight'),
				exchangeParameters: this.options.exchangeParameters,
				parent: this,
			});
			return;
		}

		STATE.showLoader();
		STATE.getPageView().getPageModel().set('nearestDatesMatrix', false);
		STATE.getPageView().getPageModel().set('currentDateMatrix', false);
		$('.b-matrix-controls__control').removeClass('active');

		this.disableElements(e);

		axios.post('/midoffice/ibecorp-b2b/avia/selectFlight', {
			parameters: {
				flightToken: (flightToken || this.model.get('flightToken')),
			},
		}).then(result => {
			STORE.set(STATE.ROUTES.AVIA_PASSENGERS, {
				flightToken: result.data.result.flightToken,
			}, 48 * 60);
			// clear previous passenger data
			STORE.remove(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`);
			STATE.set(`${STATE.ROUTES.AVIA_PASSENGERS}_MODEL`, null);
			STATE.hideOfferPreview();
			STATE.hideMenu();
			STATE.navigate(STATE.ROUTES.AVIA_PASSENGERS);
		}).catch(() => {
			STATE.hideLoader();
			Widgets.Popup.showUnknownErrorPopup();
		});
	},

	selectBrandedFare(e) {
		const $target = $(e.target);
		const index = parseInt($target.data('index'), 10);
		const fares = _.flatten(_.values(this.flightFares));
		const {flightToken} = _.find(fares, (el) => el._flightIndex === index) || {};

		if (flightToken) {
			this.select(e, flightToken);
		}
	},

	selectBrandedFareOffer(e) {
		if (e != null) {
			e.preventDefault();
		}

		const index = parseInt(e.target?.dataset?.index, 10);

		if (isNaN(index)) return this;

		if (this.flightFaresResult[index] == null || this.options.parent == null || !_.isFunction(this.options.parent.addOffer)) {
			return this;
		}

		this.options.parent.addOffer.call(this.options.parent, e, {
			flight: this.flightFaresResult[index].flight,
			flightToken: this.flightFaresResult[index].flightToken,
		});

		return this;
	},

	openRule16(e) {
		let target = null;
		if (e instanceof $.Event) {
			e.preventDefault();
			e.stopPropagation();
			target = $(e.currentTarget);
		}
		if (!target || !target.data('token')) return;

		const params = { token: target.data('token') };

		GlUl.openRuleCarrier.call(this, e, params, true);
	},

	showBrandedFares(e, container) {
		if (e != null) {
			e.preventDefault();
		}

		const $container = container != null ? container : this.$el.find('.b-flight-ticket__branded-fares');
		if (this.flightFares == null) {
			STATE.showLoader();
			this.disableElements(e);

			axios.post('/midoffice/ibecorp-b2b/avia/searchFlightFares', {
				parameters: {
					flightToken: this.model.get('flightToken'),
				},
			}).then((response) => {
				STATE.hideLoader();
				const {result} = response.data;

				if (_.isEmpty(result.flights)) {
					const popup = new Widgets.Popup({
						content: L10N.get('avia.emptyOtherFares'),
						closeOnlyOnAction: true,
						type: 'info',
						actions: [{
							label: L10N.get('Common.close'),
							action: () => {
								popup.hide();
							},
						}],
					});
					popup.show();
					return;
				}

				this.loadBrandedFares(result.flights);

				const exchangeBlock = exchangeTemplate({
					services: {
						exchange: result.flights[0].flight.exchange,
						refund: result.flights[0].flight.refund,
					},
					isFooterDisabled: true,
					isList: true,
					airlineCode: result.flights[0].flight.carrier.airlineCode,
				});

				this.$el.find(`.g-icon-option-refund-exchange-OFF-black`).attr(`data-original-title`, exchangeBlock);

				$container.empty();

				const addBrandedFares = (bf, i, collection) => {
					if (!_.isEmpty(bf)) {
						const $template = brandedFaresTemplate({
							flightFares: bf,
							hasTravelPolicy: result.hasTravelPolicy,
							flight: this.model.get('flight'),
							approvalAllowed: this.approvalAllowed,
							issueAllowed: this.issueAllowed,
						});

						const { segments } = _.first(bf).leg;
						const title = 
								`${(_.first(segments).departureCity || {}).caption || '--'} - ${(_.last(segments).arrivalCity || {}).caption || '--'}`;

						$container.append(`
								<div
									class="b-branded-services__item-container ${(i === 0 ? 'first' : '')} ${(i === collection.length ? 'last' : '')}"
									data-flight-index="${i}">
									<div
										class="${(i !== 0 ? 'collapsed' : '')} collapse--with-anchor b-branded-services__caption"
										data-toggle="collapse"
										data-target=".b-fares-container-${i}">
										${title}
									</div>
									<div class="collapse relative b-fares-container-${i} ${(i === 0 || i === collection.length ? 'show' : '')}">
										${$template}
									</div>
								</div>
							`);

						$container.find('.js-searchFlightFares').on('click', this.openRule16.bind(this));
					}
				};

				_.each(this.flightFares.brandedFares, addBrandedFares);

				if (!_.every(this.flightFares.brandedFares, _.isEmpty)) {
					if (this.flightFares.brandedFares.length >= 2) {
						addBrandedFares(
							_.last(this.flightFares.brandedFares),
							this.flightFares.brandedFares.length,
							this.flightFares.brandedFares,
						);
					}

					this.bindWidgets();
					this.checkBrandedFaresContainer();
				}

				_.each(this.flightFares.standartFares, (sf, i) => {
					if (!_.isEmpty(sf)) {
						const $template = standartFaresTemplate({
							flightFares: sf,
							hasTravelPolicy: result.hasTravelPolicy,
							approvalAllowed: this.approvalAllowed,
							issueAllowed: this.issueAllowed,
						});

						const { segments } = _.first(sf).leg;
						const title = 
								`${(_.first(segments).departureCity || {}).caption || '--'} - ${(_.last(segments).arrivalCity || {}).caption || '--'}`;
						this.$el.find('.b-flight-ticket__standart-fares').append(`
								<div class="b-standart-services__item-container">
									<div
										class="${(i !== 0 ? 'collapsed' : '')} collapse--with-anchor b-standart-services__caption"
										data-toggle="collapse"
										data-target=".b-standart-fares-container-${i}">
										${title}
									</div>
									<div class="collapse relative b-standart-fares-container-${i} ${(i === 0 ? 'show' : '')}">
										${$template}
									</div>
								</div>
							`).show();
					}
				});

				if (!_.every(this.flightFares.standartFares, _.isEmpty)) {
					this.bindWidgets();
					this.$el.find('.b-flight-ticket__standart-fares').append('<div class="b-flight-ticket__expand-branded-fares b-expand"></div>');
				} else {
					$container.append('<div class="b-flight-ticket__expand-branded-fares b-expand"></div>');
				}

				this.openBrandedFares(e);
				this.checkBrandedFaresContainer(null);
				$(window).on('resize', this.checkBrandedFaresContainer.bind(this, null));
			}).catch(() => {
				STATE.hideLoader();
				Widgets.Popup.showUnknownErrorPopup();
			});
		} else {
			this.openBrandedFares(e);
			this.checkBrandedFaresContainer(null);
		}
	},

	showBrandedFaresMobile(e) {
		if (e) e.preventDefault();

		this.showMobileOfferPreview(e);
		this?.aviaOfferPreview?.showBrandedFares?.(e, true);
	},

	showAdditionalDetails(e) {
		const id = e.target.getAttribute('data-additional-details-id');
		const flight = this.flightFaresResult[id].flight;
		const flightFare = this.flightFares.brandedFares[0][id];
		const brandedFare = this.flightFares.brandedFares[0][id];
		const additionalDetails = brandedFare.servicesDetails.additionalDetails;

		const additionalDetailsPopup = new Widgets.Popup({
			title: L10N.get('bookingForm.additionalDetails'),
			content: AdditionalDetailsTemplate({
				flight,
				flightFare,
				additionalDetails,
			}),
			type: 'info',
			actions: [{
				label: L10N.get('Common.close'),
				action: () => {
					additionalDetailsPopup.hide();
				},
			}],
		});
		additionalDetailsPopup.show();
	},

	openRuleCarrier(e) {
		let target = null;
		if (e instanceof $.Event) {
			e.preventDefault();
			e.stopPropagation();
			target = $(e.currentTarget);
		}
		if (!target || !target.data('token')) return;

		const fareRulesBase64 = target.data('fare-rules');

		const params = { token: target.data('token') };

		if (fareRulesBase64) {
			const rulesToken = Formatter.atob(fareRulesBase64);
			params.rulesToken = rulesToken;
		}

		GlUl.openRuleCarrier.call(this, e, params);
	},

	openExchangeModal() {
		this.exchangeModal = new ExchangeModalView({

		});
	},

	remove() {
		const lLayout = $('.l-layout');
		lLayout.removeClass('show-offer-preview');
		STATE.setIsAviaOfferPreviewOpened(false);
		BaseView.prototype.remove.call(this);
	},

});
